// Core
import React, { FC, useMemo, useState } from 'react'
import { FastField, FieldProps } from 'formik'
import {
  TextFieldProps,
  TextField as TextFieldMaterial,
  Box,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
// Hooks
import { useInputValuePath } from 'core/hooks'
// Utils
import { getErrorsState } from 'core/form/hooks/use-field-controller'
// Styles
import useStyles from './text-field.styles'

type TProps = {
  name: string
  localized?: boolean
} & TextFieldProps

const defaultProps = (props: any): TProps => ({
  fullWidth: true,
  variant: 'outlined',
  ...props,
})

const TextField: FC<TProps> = (props) => {
  const classes = useStyles()
  const { name, localized = false, ...rest } = defaultProps(props)
  const valuePath = useInputValuePath(localized, name)

  if (!valuePath) return null

  return (
    <Box className={classes.root}>
      <FastField name={valuePath}>
        {({ field, meta, form }: FieldProps) => {
          const computedOnBlur = (e: any) => {
            field.onBlur(e)
            rest.onBlur?.(e)
          }
          const { hasError, errorMsg } = getErrorsState(meta, form.validateOnMount)

          return (
            <Field
              {...field}
              {...rest}
              onBlur={computedOnBlur}
              error={hasError}
              helperText={errorMsg}
            />
          )
        }}
      </FastField>
    </Box>
  )
}

const Field: FC<TextFieldProps> = (props) => {
  const [isPassword] = useState(props.type === 'password')

  const [showPassword, setShowPassword] = useState(false)

  const showPasswordButton = useMemo(() => {
    if (!isPassword) return

    return (
      <InputAdornment position="end">
        <IconButton size="small" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    )
  }, [isPassword, showPassword])

  const inputType = useMemo(() => {
    if (isPassword) {
      return showPassword ? 'text' : 'password'
    }

    return props.type
  }, [isPassword, props.type, showPassword])

  return (
    <TextFieldMaterial
      {...props}
      InputProps={{
        ...props.InputProps,
        type: inputType,
        endAdornment: showPasswordButton,
      }}
    />
  )
}

export default TextField
